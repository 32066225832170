<template>
<div class="activity-chart">
    <app-echart-gradient-line :optionData="option" :line="lineData"/>
    
</div>
</template>

<script>
import AppEchartGradientLine from '@core/components/charts/echart/AppEchartGradientLine.vue'
import { $themeColors } from '@themeConfig'

export default {
components: {
    AppEchartGradientLine,
},

data() {
    return {
        option:{
            xAxisData: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ],
            
            series:[
                {
                    name: 'Current year',
                    type: 'line',
                    smooth: true,
                    data: [90, 70, 80, 75, 85, 90, 70, 85, 95, 80, 65, 70],
                    lineStyle: {
                    color: '#FF4974',
                    width: 3,
                    },
                    
                    itemStyle: {
                    color: '#FF4974',
                    },
                    emphasis: {
                    focus: 'series',
                    },
                    
                    symbol: 'circle',
                    symbolSize: 8,
                },
                {
                    name: 'Previous year',
                    type: 'line',
                    smooth: true,
                    data: [85, 65, 70, 72, 78, 85, 65, 75, 80, 82, 60, 68],
                    lineStyle: {
                    color: '#FFC069',
                    width: 3,
                    },
                    itemStyle: {
                    color: '#FFC069',
                    },
                    emphasis: {
                    focus: 'series',
                    },
                    symbol: 'circle',
                    symbolSize: 8,
                },
            ]
        },
        
    };
},
};
</script>

<style scoped>
.activity-chart {
    height: 28rem;
    display: flex;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-container {
    width: 70%;

}

.statistics {
width: 30%;
padding-left: 16px;
display: flex;
flex-direction: column;
justify-content: space-around;
}

.stat {
display: flex;
align-items: center;
justify-content: space-between;
}

.stat span {
color: #999;
}

.stat strong {
font-size: 1.2em;
}

.percentage {
background-color: #fad9e7;
color: #8c2750;
padding: 2px 8px;
border-radius: 12px;
font-weight: bold;
font-size: 0.9em;
}
</style>
