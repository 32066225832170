<template>
  <section id="dashboard-ecommerce">
    <div class="p-1" :style="$store.state.appConfig.layout.skin === 'dark' ? '' :'background-color: #d1d5db;'" >
      <b-row>
        <b-col :md="12" class="overflow-hidden">
        
        <b-row v-if="load">
          <b-col md="12" class="d-flex justify-content-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col v-if="courses" ref="hooperWrapper">
            <hooper
              :settings="hooperSettings"
              :infiniteScroll="false"
              ref="carousel"
              class="mb-1 w-100 hooper1"
              @slide="updateCarousel"
            >
              
              <slide
                class="slide"
                v-for="(item, index) in courses.data"
                :key="item.id"
                style="max-height: 290px"
              >
                <div @click="makeSlideActive(index, item)" class="cursor-pointer w-100 h-100">
                  <div class="p-2">
                    <img :src="item.cover_image" alt class="w-100" />
                  </div>
                  <div class="card-title">
                    <h4>{{item.name}}</h4>
                    <p class="mb-0">{{item.total_modules}} modules</p>
                  </div>
                </div>
              </slide>
            </hooper>
          </b-col>
        </b-row>
        
      </b-col>
      </b-row>
    </div>
    <b-row class="align-items-center p-1">
      <b-col class="col-md-6 col-sm-12">
        <b-row >
          <b-col class="col-md-6 p-50">
            <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex border p-2 rounded-lg bg-black shadow-sm' : 'd-flex border p-2 rounded-lg bg-white shadow-sm' ">
              <div>
                <img src="@/assets/images/dashboard/1.png" alt />
              </div>
              <div class="px-1">
                <p class="m-0">Total no.of schools</p>
                <div class="d-flex align-item-center justify-content-start">
                  <h3 class="font-weight-bolder m-0">10000</h3>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="col-md-6 p-50">
            <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex border p-2 rounded-lg bg-black shadow-sm' : 'd-flex border p-2 rounded-lg bg-white shadow-sm' ">
              <div>
                <img src="@/assets/images/dashboard/1.png" alt />
              </div>
              <div class="px-1">
                <p class="m-0">Total no.of Students</p>
                <div class="d-flex align-item-center justify-content-start">
                  <h3 class="font-weight-bolder m-0">10000</h3>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-md-6 col-sm-12">
        <div class="d-flex align-item-center justify-content-end" >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2 rounded-pill"
            @click="exportStudents()"
          >
            <feather-icon
              icon="ExternalLinkIcon"
              class="mr-25"
            />
            <span >Export</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-5 col-sm-12">
        <main-admin-bar-chart/>
      </b-col>
      <b-col class="col-md-7 col-sm-12">
        <main-admin-line-cross/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="col-md-4 col-sm-12">
        <main-admin-area-chart-one/>
      </b-col>
      <b-col class="col-md-4 col-sm-12">
        <main-admin-area-chart-video/>
      </b-col>
      <b-col class="col-md-4 col-sm-12">
        <main-admin-area-chart-login/>
      </b-col>
    </b-row>
    <div>
      <top-schools/>
    </div>
    
  </section>
</template>

<script>

import topSchools from '@/views/dashboard/HODDashboard/topSchools.vue';
import mainAdminBarChart from './adminDashboard/mainAdminBarChart.vue';
import mainAdminAreaChartOne from './adminDashboard/mainAdminAreaChartVWE.vue';
import mainAdminAreaChartVideo from './adminDashboard/mainAdminAreaChartVideo.vue';
import mainAdminAreaChartLogin from './adminDashboard/mainAdminAreaChartLogin.vue';
import mainAdminLineCross from './adminDashboard/mainAdminLineCross.vue'
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BAlert,
} from "bootstrap-vue";

export default {
  computed: {
    ...mapGetters({
      courses: "courses/items",
      load: "courses/load",
    })
  },
  directives: {
    Ripple,
  },
  components: {
    mainAdminLineCross,
    mainAdminAreaChartLogin,
    mainAdminAreaChartVideo,
    mainAdminAreaChartOne,
    mainAdminBarChart,
    topSchools,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BTab,
    BTabs,
    BSpinner,
    BAlert,
    BBadge,
    Hooper,
    Slide,
    BCardImg,
  },

  data() {
    return {
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: true,
        infiniteScroll: false,
        breakpoints: {
          500: {
            centerMode: false,
            itemsToShow: 3
          },
          800: {
            itemsToShow: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      },
    }
  },
  methods: {
    makeSlideActive(index, item) {
      this.$refs.carousel.slideTo(index + 1);
    },
    slidePrev(event, ss) {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    slidePrev2(event, ss) {
      this.$refs.carousel2.slidePrev();
    },
    slideNext2() {
      this.$refs.carousel2.slideNext();
    },
    updateCarousel(payload) {
      // this.filter.course_id =
      //   payload.currentSlide == 0
      //     ? null
      //     : this.courses.data[payload.currentSlide - 1].id;
      // this.moduleDetails = null;
      // this.$store.dispatch("modules/list", { ...this.filter }).then(_ => {});
    },
    updateCarousel2(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    removeActiveArticleClass() {
      const activeElements = document.querySelectorAll(".active-artical");
      activeElements.forEach(element => {
        element.classList.remove("active-artical");
      });
    },
    updateModuleDetails(item) {
      this.removeActiveArticleClass();
      if (this.moduleDetails && this.moduleDetails.id == item.id) {
        this.moduleDetails = null;
        var element = document.getElementById(`article${item.id}`);
        element.classList.remove("active-artical");
      } else {
        this.moduleDetails = item;
        var element = document.getElementById(`article${item.id}`);
        element.classList.add("active-artical");
      }
    },
    disableWheelScroll() {
      const hooperElement = this.$refs.hooperWrapper;

      hooperElement.addEventListener(
        "wheel",
        event => {
          event.stopPropagation();
          event.preventDefault();
        },
        { passive: false }
      );
    },
  },
  beforeMount() {
    this.$store.dispatch("courses/list");
    
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';

  .hooper {
  height: auto !important;
}

.hooper1 .slide {
  /* background-color: black; */
  position: relative !important;
}
.hooper-slide {
  width: 300px;
}
// .hooper1 .is-current {
//   margin-top: -40px;
// }
.nav-arrows {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10%;
  width: 40px;
  height: 40px;
}
.hooper1 .card-title {
  position: absolute !important;
  bottom: 8%;
  left: 13%;
  color: white;
  /* font-size: 1vw; */
}
.hooper1 .card-title h4 {
  color: white;
  margin-bottom: 0;
}

.card-body {
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title {
  margin-bottom: 10px !important;
}
article {
  border-radius: 60px !important;
  background: transparent !important;
}
.card-img-top {
  margin-bottom: -21px !important;
}

.custom-lesson-container {
  position: relative;
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}
.custom-lesson-container-dark {
  position: relative;
  width: 100%;
  border: 2px solid #2f2f2ffc;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.custom-lesson-container-dark .img-holder {
  height: 165px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.custom-lesson-container-dark .content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #0b0b0bfc;
  margin-top: -25px;
  border-radius: 20px;
}

/* .custom-lesson-container-dark .text-muted{
    color: #9CA3AF !important;
} */

.custom-lesson-container-dark .active-artical small {
  color: #fff !important;
}

/* .active-artical img{
    border: 2px solid #E84185 !important;
} */

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}

.hooper-list {
  overflow: visible !important;
  width: 100%;
  height: 100%;
}
.html-wrapper{
  overflow: hidden;
  height: 37px;
}
</style>
