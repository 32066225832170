<template>
<b-card title="Video watch duration">
    <b-card-text>
        Average time spent watching video
    </b-card-text>
    <div class="chart-area-container">
        <app-echart-stacked-area  :option-data="option" :style="{ width: '100%' ,height:'300px'}" />
    </div>

</b-card>
</template>

<script>
import { BCard,BCardText } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
components: {
    BCard,
    BCardText,
    AppEchartStackedArea,
},
data() {
  return {
    option: {
      xAxis: {
        type: 'category',
        data: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12', '18/12', '19/12', '20/12'],
        show: false,  // Hide x-axis
      },
      yAxis: {
        type: 'value',
        show: false,  // Hide y-axis
      },
      series: [
        {
          // name: 'Point Two',
          type: 'line',
          stack: 'Total',
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: '#a52c88' },    // Start color (at the top)
                { offset: 1, color: 'rgba(41, 154, 255, 0)' } // End color (transparent at the bottom)
              ]
            },
          },
          color: '#a52c88',
          showSymbol: false,
          smooth: 0.4,
          lineStyle: {
            width: 0,
          },
          data: [200, 150, 281, 634, 490, 430, 310, 450, 500, 600, 550, 750, 560, 550],
        },
      ],
    }
  }
}

}
</script>
<style scoped>
    .chart-area-container{
      margin-top: -33px;
      margin-left: -22px;
      width: 110%;
      height: 16rem;
    }
</style>