<template>
<div class="activity-chart">
    
    <div class="chart-container">
        <chartjs-component-bar-chart
            :height="350"
            :data="latestBarChart.data"
            :options="latestBarChart.options"
        />
    </div>
    <div class="statistics">
    <div >
        <div>
            <span>Time spent</span>
        </div>
        <div class="stat">
            <strong style="color: #111928;">18h</strong>
            <span class="percentage">84%</span>
        </div>
    </div>
    <div >
        <div>
            <span>Lessons taken</span>
        </div>
        <div class="stat">
            <strong style="color: #111928;">50</strong>
            <span class="percentage">84%</span>
        </div>
    </div>
    <div >
        <div>
            <span>Quizzes passed</span>
        </div>
        <div class="stat">
            <strong style="color: #111928;">10</strong>
            <span class="percentage">100%</span>
        </div>
    </div>
    
    </div>
</div>
</template>

<script>
import ChartjsComponentBarChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'
const chartColors = {
    primaryColorShade: '#e84185',
    yellowColor: '#ffe800',
    successColorShade: '#e84185',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }
export default {
components: {
    ChartjsComponentBarChart,
},

data() {
    return {
        latestBarChart: {
            data: {
            labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            datasets: [
                {
                data: [5.8, 6.5, 8.1, 7.7, 1, 6, 1],
                backgroundColor: chartColors.successColorShade,
                borderColor: 'transparent',
                },
            ],
            },
            options: {
            elements: {
                rectangle: {
                borderWidth: 0,
                borderSkipped: 'bottom',
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
                display: false,
            },
            tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: chartColors.tooltipShadow,
                backgroundColor: $themeColors.light,
                titleFontColor: $themeColors.dark,
                bodyFontColor: $themeColors.dark,
            },
            scales: {
                xAxes: [
                {
                    display: true,
                    gridLines: {
                    display: true,
                    color: chartColors.grid_line_color,
                    zeroLineColor: chartColors.grid_line_color,
                    },
                    scaleLabel: {
                    display: false,
                    },
                    ticks: {
                    fontColor: chartColors.labelColor,
                    },
                },
                ],
                yAxes: [
                {
                    display: true,
                    gridLines: {
                    color: chartColors.grid_line_color,
                    zeroLineColor: chartColors.grid_line_color,
                    },
                    ticks: {
                    stepSize: 1,
                    min: 0,
                    max: 10,
                    fontColor: chartColors.labelColor,
                    callback: function(value) {
                        return `${value}h`;
                    }
                    },
                },
                ],
            },
            },
        },
    };
},
};
</script>

<style scoped>
.activity-chart {
    height: 28rem;
    display: flex;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-container {
    width: 70%;

}

.statistics {
width: 30%;
padding-left: 16px;
display: flex;
flex-direction: column;
justify-content: space-around;
}

.stat {
display: flex;
align-items: center;
justify-content: space-between;
}

.stat span {
color: #999;
}

.stat strong {
font-size: 1.2em;
}

.percentage {
background-color: #fad9e7;
color: #8c2750;
padding: 2px 8px;
border-radius: 12px;
font-weight: bold;
font-size: 0.9em;
}
</style>
