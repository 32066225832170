<template>
  <div>
    <div class="row">
      
      <div class="col-12">
        <div class="rounded-lg mt-2">
          
          <b-row :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card pt-1 pb-1' : 'custom-card-border pt-1 pb-1'" v-if="!loadSchools">
           
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="pl-2 d-flex align-items-center">
                <p :style="$store.state.appConfig.layout.skin === 'dark' ? 'font-size: large; font-weight: 500;' :'font-size: large; color: #111928; font-weight: 500;'" class="m-0">Top performing schools</p>
                <b-link class="text-primary ml-2" :to="'schools'">
                  View all schools
                  <feather-icon icon="ArrowRightIcon" size="20"/>
                </b-link>
              </div>
              <div class="d-flex justify-content-center">
                <div
                  @click.prevent="slidePrev"
                  :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary icon-arrow' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle  p-1 cursor-pointer icon-arrow'"
                >
                  <feather-icon icon="ChevronLeftIcon" size="20"/>
                  <!-- <i class="fa-solid fa-arrow-left" ></i> -->
                </div>
                <div
                  @click.prevent="slideNext"
                  :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary icon-arrow' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle  p-1 cursor-pointer icon-arrow'"
                >
                  <!-- <i class="fa-solid fa-arrow-right"></i> -->
                  <feather-icon icon="ChevronRightIcon" size="20"/>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between pl-2">
                  <p style="font-size: large; color: #111928; font-weight: 500;">Top performing schools</p>
              </div> -->
            <hooper :settings="hooperSettings2" ref="carousel2" @slide="updateCarousel2" >
              
              <slide
                class="slide px-1"
                v-for="(item,index) in schools"
                :key="item.id"
                style="max-height: 270px;"
              >
              <div class="custom-ver-border mt-2  d-flex  align-items-center">
                          
                  
                  <div class="top-sort">
                    {{ index+1 }}
                  </div>
                  <div class="text-center" style="width: 100%;">
                    <img  class="top-img" src="@/assets/images/dashboard/1.png" alt />
                    <p  style="font-size: large; color: #111928; font-weight: 500; padding-top:0.5rem">{{ item.name }}</p>
                  </div>
                  
              </div>
              </slide>
            </hooper>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
  BootstrapVueIcons,
  IconsPlugin,
  BIconArrowUp,
  BIcon
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import CardAdvanceEmployeeTask from "@/views/card/card-advance/CardAdvanceEmployeeTask.vue";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BLink,
    ToastificationContent,
    BSpinner,
    BPagination,
    BFormInput,
    BootstrapVueIcons,
    CardAdvanceEmployeeTask,
    IconsPlugin,
    BIconArrowUp,
    BIcon,
    Hooper,
    Slide,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {},
  computed: {
    ...mapGetters({
      schools: "adminDashboard/schools",
      loadSchools: "adminDashboard/loadSchools",
    })
  },
  data() {
    return {
      hooperSettings2: {
        itemsToShow: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: false,
        itemsToSlide: 5,
        infiniteScroll: false,
        breakpoints: {
          500: {
            centerMode: false,
            itemsToSlide: 1,
            itemsToShow: 1
          },
          800: {
            itemsToShow: 4,
            itemsToSlide: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      }
    };
  },
  methods: {
    makeSlideActive(index, item) {
      this.$refs.carousel.slideTo(index + 1);
    },
    slidePrev(event, ss) {
      this.$refs.carousel2.slidePrev();
    },
    slideNext() {
      this.$refs.carousel2.slideNext();
    },
    updateCarousel(payload) {
      this.filter.course_id =
        payload.currentSlide == 0
          ? null
          : this.courses.data[payload.currentSlide - 1].id;
      this.moduleDetails = null;
      // this.$store.dispatch("modules/list", { ...this.filter }).then(_ => {});
    },
    updateCarousel2(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    removeActiveArticleClass() {
      const activeElements = document.querySelectorAll(".active-artical");
      activeElements.forEach(element => {
        element.classList.remove("active-artical");
      });
    },
    updateModuleDetails(item) {
      this.removeActiveArticleClass();
      if (this.moduleDetails && this.moduleDetails.id == item.id) {
        this.moduleDetails = null;
        var element = document.getElementById(`article${item.id}`);
        element.classList.remove("active-artical");
      } else {
        this.moduleDetails = item;
        var element = document.getElementById(`article${item.id}`);
        element.classList.add("active-artical");
      }
    },
    disableWheelScroll() {
      const hooperElement = this.$refs.hooperWrapper;

      hooperElement.addEventListener(
        "wheel",
        event => {
          event.stopPropagation();
          event.preventDefault();
        },
        { passive: false }
      );
    },
    enrol(item) {
      if (this.moduleDetails.assigned) {
        this.$router.push({
          name: "module_introduction",
          params: { id: item.id }
        });
      } else {
        this.$store
          .dispatch("lessonPlans/enrolUser", item.id)
          .then(res => {
            this.$swal({
              icon: "success",
              title: `<h4> ${res.message} </h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          })
          .catch(error => {
            if (error.response) {
              this.$swal({
                icon: "error",
                title: `<h4>${error.response.data.error}</h4>`,
                showConfirmButton: true,
                confirmButtonColor: "#E84185",
                allowOutsideClick: true
              });
            }
          });
      }
    }
  },
  mounted() {
    this.$store.dispatch("adminDashboard/topSchools");
  }
};
</script>
  

<style lang="css" scoped>
.icon-arrow{
  color: #fff;
  background-color: #e84185;
}
.top-img{
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 50%;
}
.top-sort{
  color: #fff;
    position: absolute;
    top: 34px;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #111928;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10px;
}
.custom-card-border{
  background-color: #fff;
  border-radius: 20px;
}
.hooper {
  height: auto !important;
}

.hooper1 .slide {
  /* background-color: black; */
  position: relative !important;
}
.hooper-slide {
  width: 300px;
}
.hooper1 .is-current {
  margin-top: -40px;
}
.nav-arrows {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10%;
  width: 40px;
  height: 40px;
}
.hooper1 .card-title {
  position: absolute !important;
  bottom: 8%;
  left: 13%;
  color: white;
  /* font-size: 1vw; */
}
.hooper1 .card-title h4 {
  color: white;
  margin-bottom: 0;
}

.card-body {
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title {
  margin-bottom: 10px !important;
}
article {
  border-radius: 60px !important;
  background: transparent !important;
}
.card-img-top {
  margin-bottom: -21px !important;
}

.custom-lesson-container {
  position: relative;
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}
.custom-lesson-container-dark {
  position: relative;
  width: 100%;
  border: 2px solid #2f2f2ffc;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.custom-lesson-container-dark .img-holder {
  height: 165px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.custom-lesson-container-dark .content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #0b0b0bfc;
  margin-top: -25px;
  border-radius: 20px;
}

/* .custom-lesson-container-dark .text-muted{
    color: #9CA3AF !important;
} */

.custom-lesson-container-dark .active-artical small {
  color: #fff !important;
}

/* .active-artical img{
    border: 2px solid #E84185 !important;
} */

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}

/deep/.hooper-list {
  overflow: visible !important;
  width: 100%;
  height: 100%;
}
.html-wrapper {
  overflow: hidden;
  height: 37px;
}
.custom-ver-border{
        border: 2px solid #e5e7eb ;
        border-radius: 20px !important;
        height: 150px;
        /* width: 20rem; */
      background-color: #fff;
    }
    .img-ver-holder{
        width: 40%;
        height: 100%;
        justify-content: center;
        overflow: hidden;
    }
    .img-ver-holder img{
        height: 100%;
    width: 100%;
    border-radius: 20px;
    }
</style>
  