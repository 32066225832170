<template>
  
    <e-charts
      ref="line"
      autoresize
      :options="line"
      theme="theme-color"
      auto-resize
    />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
    
  },
  data() {
    return {
      line: {
        color: ['#FF4974', '#FFC069'],
        tooltip: {
          trigger: 'axis',
          formatter: '{b} : {c}%',
          backgroundColor: 'rgba(255, 0, 0, 0.8)',
          borderRadius: 8,
          padding: 10,
          textStyle: {
            color: '#fff',
          },
        },
        legend: {
          data: ['Current year', 'Previous year'],
          top: '5%',
          right: '10%',
          icon: 'circle',
          textStyle: {
            color: '#333',
            fontWeight: 'bold',
          },
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.optionData.xAxisData,
          axisLine: { lineStyle: { color: '#E0E0E0' } },
          axisLabel: {
            color: '#999',
          },
        },
        yAxis: {
          type: 'value',
          min: 50,
          max: 100,
          axisLine: { show: false },
          axisLabel: {
            formatter: '{value}%',
            color: '#999',
          },
          splitLine: { lineStyle: { color: '#E0E0E0' } },
        },
        series: this.optionData.series
      },
    };
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
